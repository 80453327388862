import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Header, SubHeader } from "../../components/Header";
import VesselItemsTable from "../../components/VesselItemsTable/VesselItemsTable";
import VesselSelector from "../../components/VesselSelector/VesselSelector";
import { ConfiguredGrid } from "../../pageConfig/ConfiguredGrid";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import Button from "../../../common/components/Button";
import { PerformanceGaugeContext } from "../../../common/components/PerformanceGauge/PerformanceGauge";
import { selectPageConfig } from "../../reducers/reducer.pageConfig";
import styles from "./Vessel.css";
import { fetchItemTypeData } from "../../actions";
import { fetchVesselFuelConsumption } from "../../actions/action.fetchVesselFuelConsumption";
import { fetchVesselDataQuality } from "../../actions/action.fetchVesselDataQuality";
import {
  convertFiltersToQueryParams,
  getDateRangeFilter,
  getStateFilter,
  getGroupFilter,
  getLimitsForVesselInQuery,
  getPerformanceIndicatorsFilter,
  getVesselFilter,
  getVesselItemsFilter,
} from "../../selectors/filters";
import { getPageContext } from "../../selectors/common";
import { VIEWS } from "../../config";
import ExportPopup from "../Export/ExportPopup";
import VesselFuelConsumptionContainer from "../../pageConfig/VesselFuelConsumptionContainer";
import DataQualityPopup from "./DataQualityPopup";
import { vesselExportConfigurations } from "./VesselExportConfig";
import { StateFilter } from "../../components/StateFilter/StateFilter";
import { getColorForSelectedItem } from "../Trend/Trend";
import { useQueryContext } from "../../providers/QueryContextProvider";
import { SelectItemsHeader } from "../../components/SelectItemsHeader/SelectItemsHeader";

const WIDGET_HEADER = {
  DONUTS: "OPERATION MODES", //keys should match componentMap keys
};

const Vessel = () => {
  const queryContext = useQueryContext();

  // Define internal component state
  const [pageConfig, setPageConfig] = useState();

  // Get values from store
  const user = useSelector((state) => state.user);
  const pageConfigs = useSelector((state) => state.pageConfig);
  const selectedVesselId = useSelector(
    (state) => state.pageContext.selectedVesselId
  );
  const selectedVessel = useSelector(
    (state) => state.pageContext.vessels[state.pageContext.selectedVesselId]
  );
  const selectedVesselGroup = useSelector(
    (state) => state.pageContext.groups[state.pageContext.selectedGroupId]
  );
  const filters = useSelector((state) =>
    getVesselPageFilters(state, { queryContext })
  );
  const pageContextVessels = useSelector((state) => state.pageContext.vessels);
  const limits = useSelector((state) =>
    getLimitsForVesselInQuery(state, { queryContext })
  );
  const selectedTrendItems = useSelector((state) =>
    getSelectedTrendItems(state, { queryContext })
  );
  const restrictedViews = useSelector(
    (state) => state.userProfile.restrictedViews
  );

  const dispatch = useDispatch();

  const showStateFilter =
    !!pageContextVessels?.[filters.vesselFilter.value]
      ?.draftLadenDetectThreshold;

  useEffect(() => {
    setPageConfig(
      selectPageConfig(
        user,
        pageConfigs,
        selectedVesselId,
        selectedVessel,
        selectedVesselGroup,
        "vessel",
        { vesselId: filters.vesselFilter.value }
      )
    );
  }, [
    user,
    pageConfigs,
    selectedVesselId,
    selectedVessel,
    selectedVesselGroup,
    filters.vesselFilter.value,
  ]);

  useEffect(() => {
    if (!filters.isValid) {
      adjustFilters();
    } else {
      fetchData(
        filters.vesselFilter.value,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset),
        filters.stateFilter
      );
    }
  }, [
    filters.vesselFilter.value,
    JSON.stringify(filters.dateFilter.value.range),
    filters.dateFilter.value.timeOffset,
    JSON.stringify(filters.stateFilter.value),
  ]);

  const fetchData = (vesselId, dateRange, timeOffset, states) => {
    dispatch(fetchItemTypeData(vesselId, dateRange, timeOffset, states));
    dispatch(
      fetchVesselFuelConsumption(vesselId, dateRange, timeOffset, states)
    );
    dispatch(fetchVesselDataQuality(vesselId, dateRange, timeOffset, states));
  };

  const adjustFilters = () => {
    queryContext.navigate(
      VIEWS.vessel.url,
      convertFiltersToQueryParams(filters),
      true
    );
  };

  const onToggleVesselItem = (id) => {
    const currentItems = get(filters, "vesselItemsFilter.value", []);
    if (currentItems.some((x) => x.id === id)) {
      queryContext.setItems(currentItems.filter((x) => x.id !== id));
    } else {
      queryContext.setItems([
        ...currentItems,
        {
          id,
          selected: "true",
          color: getColorForSelectedItem(id, currentItems),
        },
      ]);
    }
  };

  const onTogglePerformanceIndicator = (id) => {
    queryContext.toggleKpi(id);
  };

  const onShowTrend = () => {
    queryContext.navigate(
      VIEWS.trend.url,
      convertFiltersToQueryParams(filters)
    );
  };

  const onRemoveTrendItem = (item) => {
    switch (item.type) {
      case "vesselItem":
        onToggleVesselItem(item.id);
        break;
      case "performanceIndicator":
        onTogglePerformanceIndicator(item.id);
        break;
    }
  };

  const onClearTrend = () => {
    queryContext.navigate(VIEWS.vessel.url, {
      ...convertFiltersToQueryParams(filters),
      kpis: [],
      items: [],
    });
  };

  const renderCustomPerformanceGaugeFooter = ({ performanceIndicatorId }) => {
    return (
      <div className={styles.performanceTrendButtonContainer}>
        <Button
          type="content"
          clickAction={() =>
            onTogglePerformanceIndicator(performanceIndicatorId)
          }
        >
          <svg width="32" height="32">
            <g>
              <path
                fill="transparent"
                stroke="#fff"
                strokeWidth="2px"
                d="m3.525651,20.55127l5.175661,-8.92487l4.171429,8.644214l3.244444,-5.164076l3.398942,5.276338l8.342857,-10.664939"
              />
            </g>
          </svg>
        </Button>
      </div>
    );
  };

  const { vesselFilter, dateFilter, isValid, stateFilter } = filters;
  const isTrendRestricted =
    restrictedViews && restrictedViews.includes(VIEWS.trend.viewName);

  return (
    <div>
      <Header title="Vessel" contentDistribution="space-between" sticky>
        <VesselSelector />
        <div className={styles.rightHeaderContainer}>
          {isValid && showStateFilter && <StateFilter />}{" "}
          {isValid && (
            <DatePickerContainer
              current={dateFilter.value}
              limits={limits}
              vesselId={vesselFilter.value}
              onChange={queryContext.setDate}
              allowNoCurrent
            />
          )}{" "}
          {isValid && (
            <ExportPopup
              vesselId={vesselFilter.value}
              dateRange={dateFilter.value.range}
              exportConfigurations={vesselExportConfigurations}
              states={stateFilter.value}
            />
          )}
          <DataQualityPopup />
        </div>
      </Header>
      {selectedTrendItems.length > 0 && (
        <SubHeader>
          <SelectItemsHeader
            items={selectedTrendItems}
            idSelector={(v) => v.id}
            labelSelector={(v) => v.name}
            onRemoveItem={onRemoveTrendItem}
            onClick={onShowTrend}
            buttonText="Show trend"
            onClose={onClearTrend}
          />
        </SubHeader>
      )}
      {isValid && (
        <PerformanceGaugeContext.Provider
          value={{
            renderCustomFooter: isTrendRestricted
              ? undefined
              : renderCustomPerformanceGaugeFooter,
          }}
        >
          <ConfiguredGrid
            config={pageConfig}
            context={{
              vesselId: vesselFilter.value,
              dateRange: dateFilter.value.range,
              timeOffset: parseInt(dateFilter.value.timeOffset),
              states: stateFilter.value,
            }}
            header={WIDGET_HEADER}
          />
        </PerformanceGaugeContext.Provider>
      )}
      {isValid && <VesselFuelConsumptionContainer date={dateFilter.value} />}
      {isValid && (
        <VesselItemsTable
          date={dateFilter.value}
          vesselId={vesselFilter.value}
          toggleTrendVesselItem={onToggleVesselItem}
          states={stateFilter.value}
        />
      )}
    </div>
  );
};

const getVesselPageFilters = createSelector(
  [
    getVesselFilter,
    getGroupFilter,
    getDateRangeFilter,
    getVesselItemsFilter,
    getPerformanceIndicatorsFilter,
    getStateFilter,
  ],
  (
    vesselFilter,
    groupFilter,
    dateFilter,
    vesselItemsFilter,
    performanceIndicatorsFilter,
    stateFilter
  ) => {
    return {
      isValid:
        vesselFilter.isValid &&
        groupFilter.isValid &&
        dateFilter.isValid &&
        vesselItemsFilter.isValid &&
        performanceIndicatorsFilter.isValid &&
        stateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
      vesselItemsFilter,
      performanceIndicatorsFilter,
      stateFilter,
    };
  }
);

const getSelectedTrendItems = createSelector(
  [getVesselItemsFilter, getPerformanceIndicatorsFilter, getPageContext],
  (vesselItemsFilter, performanceIndicatorsFilter, pageContext) => [
    ...vesselItemsFilter.value.map(({ id }) => ({
      id,
      name: get(pageContext.vesselItems[id], "name"),
      type: "vesselItem",
    })),
    ...performanceIndicatorsFilter.value.map((id) => ({
      id,
      name: get(pageContext.performanceIndicators[id], "label"),
      type: "performanceIndicator",
    })),
  ]
);

export default Vessel;
