import t from "prop-types";
import React from "react";
import Grid from "../components/Grid/Grid";
import GridCell from "../components/Grid/GridCell/GridCell";
import BestPerformanceContainer from "./BestPerformanceContainer";
import DonutContainer from "./ConfiguredDonutContainer";
import EnergyBudgetOverviewContainer from "./EnergyBudgetOverviewContainer";
import EnergyBudgetResultContainer from "./EnergyBudgetResultContainer";
import FleetConnectionStatusContainer from "./FleetConnectionStatusContainer";
import HealthEventStatusContainer from "./HealthEventStatusContainer";
import {
  FuelEfficiencyTodayContainer,
  FuelEfficiencyYesterdayContainer,
} from "./FuelEfficiencyContainer";
import GaugeContainer from "./GaugeContainer";
import MapContainer from "./MapContainer";
import MonthlyFuelUsageContainer from "./MonthlyFuelUsageContainer";
import OperationalProfilePlanContainer from "./OperationalProfilePlanContainer";
import VesselCountContainer from "./VesselCountContainer";
import VesselMapContainer from "./VesselMapContainer";
import VesselProfileContainer from "./VesselProfileContainer";
import EquinorContainer from "./ConfiguredEquinorReportContainer";
import VesselFuelConsumptionContainer from "./VesselFuelConsumptionContainer";
import ComponentInfo from "../../common/components/ComponentInfo/ComponentInfo";
import { COMPONENT_INFO, PERFORMANCE_INDICATORS_MAP } from "../config";

const componentMap = {
  MONTHLY_FUEL_USAGE: MonthlyFuelUsageContainer,
  VESSEL_COUNT: VesselCountContainer,
  VESSEL_PROFILE: VesselProfileContainer,
  MAP: MapContainer,
  VESSEL_MAP: VesselMapContainer,
  BEST_PERFORMANCE: BestPerformanceContainer,
  FUEL_EFFICIENCY_TODAY: FuelEfficiencyTodayContainer,
  FUEL_EFFICIENCY_YESTERDAY: FuelEfficiencyYesterdayContainer,
  FLEET_CONNECTION_STATUS: FleetConnectionStatusContainer,
  HEALTH_EVENT_STATUS: HealthEventStatusContainer,
  ENERGY_BUDGET_OVERVIEW: EnergyBudgetOverviewContainer,
  ENERGY_BUDGET_RESULT: EnergyBudgetResultContainer,
  DONUTS: DonutContainer,
  GAUGE: GaugeContainer,
  OPERATIONAL_PROFILE_PLAN: OperationalProfilePlanContainer,
  EQUINOR_VESSEL_REPORT: EquinorContainer,
  VESSEL_FUEL_CONSUMPTION: VesselFuelConsumptionContainer,
};

const renderContent = (config, context, header) => {
  const component = componentMap[config.type];
  const widgetHeader = header?.[config.type];
  if (!component) {
    console.error(
      "No components are configured for grid cells of type",
      config.type,
      config
    );
    return null;
  }
  return React.createElement(component, {
    config,
    context,
    widgetHeader,
  });
};

export function ConfiguredGrid({ config, context, header }) {
  const rows = config.rows || [];
  return (
    <div>
      {rows.map((row, y) => (
        <Grid key={y}>
          {row.map((cellConfig, x) => (
            <GridCell key={x} cellSpan={cellConfig.colSpan || 1}>
              {(getIndicatorTooltipInfo(cellConfig) !== undefined && (
                <ComponentInfo
                  modalTargetId={"popupContainer"}
                  {...getIndicatorTooltipInfo(cellConfig)}
                >
                  {renderContent(cellConfig, context, header)}
                </ComponentInfo>
              )) ||
                renderContent(cellConfig, context, header)}
            </GridCell>
          ))}
        </Grid>
      ))}
    </div>
  );

  function getIndicatorTooltipInfo(cellConfig) {
    return COMPONENT_INFO[
      cellConfig.type === "GAUGE"
        ? PERFORMANCE_INDICATORS_MAP[
            cellConfig.params?.performanceIndicatorId.toUpperCase()
          ]
        : cellConfig.type
    ];
  }
}

ConfiguredGrid.propTypes = {
  config: t.shape({
    rows: t.arrayOf(
      t.arrayOf(
        t.shape({
          type: t.string.isRequired,
          colSpan: t.number,
        })
      )
    ),
  }),
  context: t.object,
};

ConfiguredGrid.defaultProps = {
  config: { rows: [] },
};
