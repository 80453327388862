import styles from "./DonutContainer.css";
import t from "prop-types";
import React, { Component } from "react";
import Donut from "../../../common/components/Donut/Donut";
import Legend from "./Legends";
import Loader from "../../../common/components/Loader";
import { ICONS } from "../../config";
import { PROFILES } from "../../../common/config";
import { getProfiles, getProfilesGroups } from "../../common/donuts";
import { arrayShallowEquals } from "../../common/arrayShallowEquals";
import { color } from "../../../common/colors";
import { get } from "lodash";

const getLegendColor = (legend) => PROFILES[legend] && PROFILES[legend].color;
const getLegendTextColor = (legend) =>
  PROFILES[legend] && PROFILES[legend].textColor;

export default class DonutContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLegends: [],
    };

    this.renderDonut = this.renderDonut.bind(this);
    this.renderLegend = this.renderLegend.bind(this);
    this.clearLegendSelection = this.clearLegendSelection.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !arrayShallowEquals(nextProps.donuts, this.props.donuts) ||
      !arrayShallowEquals(nextState.activeLegends, this.state.activeLegends)
    );
  }

  toggleLegend(legend) {
    let currentLegends = this.state.activeLegends;
    if (currentLegends.includes(legend)) {
      currentLegends = currentLegends.filter((l) => l !== legend);
    } else {
      const newLegends = currentLegends.slice();
      newLegends.push(legend);
      currentLegends = newLegends;
    }
    this.setState(
      {
        activeLegends: currentLegends,
      },
      this.setSelectedOperations
    );
  }

  setSelectedOperations() {
    this.props.onOperationSelect(this.state.activeLegends);
  }

  isLegendSelected(legend) {
    return (
      this.state.activeLegends.length === 0 ||
      this.state.activeLegends.includes(legend)
    );
  }

  clearLegendSelection() {
    this.setState({ activeLegends: [] }, this.setSelectedOperations);
  }

  getProfileId(profile) {
    if (profile === undefined) {
      return "";
    }

    if (
      get(profile, "id", "00000000-0000-0000-0000-000000000000") !==
      "00000000-0000-0000-0000-000000000000"
    ) {
      return profile.id;
    }

    return profile.legend ? profile.legend : "";
  }

  renderDonut(donut, key) {
    const { data, error } = donut ?? {};
    if (!data) {
      return (
        <div key={key} className={styles.donutContainer}>
          <Loader text={"Loading"} error={error} expand={true} />
        </div>
      );
    }

    return (
      <div key={key} className={styles.donutContainer}>
        <Donut
          testId={`${this.props.testId}-donut${key + 1}`}
          title={data.title}
          unit={data.unit}
          icon={ICONS[data.type]}
          activeNames={[...this.state.activeLegends]}
          data={[...data.data]}
          overlayEl={document.getElementById("viewContainer")}
          nameFn={(item) => (item && item.operationalProfile.legend) || ""}
          idFn={(item) => this.getProfileId(item.operationalProfile)}
          valueFn={(item) => (item && item.value) || 0}
          colorFn={(item) => getLegendColor(item.operationalProfile.legend)}
          overriddenTotal={data.overriddenTotal}
          overriddenUnit={data.overriddenUnit}
          overriddenTitle={data.overriddenTitle}
        />
      </div>
    );
  }

  renderLegend(profile, key) {
    const legend = profile.legend;
    const id = this.getProfileId(profile);
    return (
      <Legend
        {...profile}
        key={key}
        testId={`${this.props.testId}-legend-${legend}`}
        color={getLegendColor(legend)}
        textColor={getLegendTextColor(legend)}
        selected={this.isLegendSelected(id)}
        onClick={() => this.toggleLegend(id)}
      />
    );
  }

  render() {
    const operationGroups = getProfilesGroups(this.props.donuts);
    const operationGroupKeys = Object.keys(operationGroups);
    return (
      <div className={styles.mainContainer} data-test-id={this.props.testId}>
        {this.props.widgetHeader && (
          <div className={styles.widgetHeader}>
            <h2>{this.props.widgetHeader}</h2>
          </div>
        )}
        <div className={styles.donutsContainer}>
          {this.props.donuts.map(this.renderDonut)}
        </div>
        <div className={styles.legendsContainer}>
          <div className={styles.resetContainer} />
          <div className={styles.legendsInnerContainer}>
            {operationGroupKeys.map((key) => (
              <div key={key}>
                {getProfiles(operationGroups[key]).map(this.renderLegend)}
              </div>
            ))}
          </div>
          <div className={styles.resetContainer}>
            <Legend
              legend={"✖"}
              key={"clearLegends"}
              color={""}
              onClick={this.clearLegendSelection}
              selected={true}
              category={"Clear"}
              profile={"Clear Selection"}
              style={{
                border: `2px solid ${color("--light-grey")}`,
                color: `${color("--light-grey")}`,
                visibility:
                  this.state.activeLegends.length === 0 ? "hidden" : "",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

DonutContainer.defaultProps = {
  onOperationSelect: () => {},
};

DonutContainer.propTypes = {
  donuts: t.array.isRequired,
  onOperationSelect: t.func,
  testId: t.string,
  widgetHeader: t.string,
};
