import React from "react";
import { connect } from "react-redux";
import styles from "./Fleet.css";
import { Header, SubHeader } from "../../components/Header";
import MetricTable from "../../components/MetricTable";
import SelectBox from "../../components/SelectBox";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import Loader from "../../../common/components/Loader/Loader";
import { ConfiguredGrid } from "../../pageConfig/ConfiguredGrid";
import { selectPageConfig } from "../../reducers/reducer.pageConfig";
import {
  fetchCompare,
  fetchFleetPageData,
  removeAllCompareVessel,
} from "../../actions";
import {
  getNonEmptyVesselGroups,
  getVesselsInGroup,
} from "../../reducers/reducer.pageContext";
import {
  convertFiltersToQueryParams,
  getDateRangeFilter,
  getGroupFilter,
  getLimitsForVesselInQuery,
} from "../../selectors/filters";
import { VIEWS } from "../../config";
import { Pagination } from "../../components/Pagination";
import { SelectItemsHeader } from "../../components/SelectItemsHeader/SelectItemsHeader";

const WIDGET_HEADER = {
  DONUTS: "OPERATION MODES", //keys should match componentMap keys
};

const context = ({ filters }) => {
  return {
    groupId: filters.groupFilter.value,
    dateRange: filters.dateFilter.value && filters.dateFilter.value.range,
    timeOffset:
      filters.dateFilter.value && parseInt(filters.dateFilter.value.timeOffset),
  };
};

const PaginationSettings = {
  postsPerPage: 20,
};

class Fleet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewTitle: "Fleet",
      compareVessels: [],
    };
    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.toggleCompare = this.toggleCompare.bind(this);
    this.compare = this.compare.bind(this);
    this.clearCompare = this.clearCompare.bind(this);
    this.vesselClick = this.vesselClick.bind(this);
  }

  onGroupSelect(group) {
    const { queryContext } = this.props;
    queryContext.setSelectedGroupId(group, null);
  }

  onDateSelect(date) {
    const { queryContext } = this.props;
    queryContext.setDate(date);
  }

  componentDidMount() {
    const { filters, fetchFleetPageData, queryContext } = this.props;
    if (filters.isValid) {
      fetchFleetPageData(filters, []);
    } else {
      queryContext.navigate(
        VIEWS.fleet.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { filters, fetchFleetPageData, queryContext } = this.props;
    if (filters.isValid) {
      fetchFleetPageData(filters, prevProps.filters);
    } else {
      queryContext.navigate(
        VIEWS.fleet.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }

  vesselClick(vessel) {
    const { queryContext } = this.props;
    queryContext.navigate(VIEWS.vessel.url, { vesselId: vessel.id });
  }

  toggleCompare(vessel) {
    let { compareVessels } = this.state;

    if (compareVessels.some((v) => v.id === vessel.id)) {
      let copy = compareVessels.slice();
      copy.splice(
        copy.findIndex((v) => v.id === vessel.id),
        1
      );
      this.setState({ compareVessels: copy });
    } else {
      this.setState({ compareVessels: compareVessels.concat(vessel) });
    }
  }

  clearCompare() {
    this.setState({ compareVessels: [] });
  }

  compare() {
    const { removeAllCompareVessel, fetchCompare, filters, queryContext } =
      this.props;
    const { compareVessels } = this.state;

    removeAllCompareVessel();
    const { dateFilter } = filters;
    for (const compareVessel of compareVessels) {
      fetchCompare(
        compareVessel.id,
        dateFilter.value.range,
        dateFilter.value.type,
        parseInt(dateFilter.value.timeOffset)
      );
    }
    queryContext.navigate(VIEWS.compare.url);
  }

  render() {
    const {
      groups,
      pageConfig,
      limits,
      filters,
      queryContext,
      numberOfVesselsInGroup,
      fleetTableIsLoading,
      vessels,
      table,
    } = this.props;
    const { viewTitle, compareVessels } = this.state;
    const paginate = (pageNumber) => {
      queryContext.setPageNum(pageNumber);
    };

    return (
      <div>
        <Header title={viewTitle} contentDistribution="space-between" sticky>
          <SelectBox
            options={groups}
            optionValKey={"id"}
            optionLabelKey={"name"}
            onSelect={this.onGroupSelect}
            selected={groups.find((x) => x.id === filters.groupFilter.value)}
          />

          {filters.isValid && (
            <DatePickerContainer
              limits={limits}
              current={filters.dateFilter.value}
              onChange={this.onDateSelect}
              allowNoCurrent
            />
          )}
        </Header>
        {compareVessels.length > 0 && (
          <SubHeader>
            <SelectItemsHeader
              items={compareVessels}
              idSelector={(v) => v.id}
              labelSelector={(v) => v.name}
              onRemoveItem={this.toggleCompare}
              onClick={this.compare}
              buttonText="Compare"
              onClose={this.clearCompare}
            />
          </SubHeader>
        )}

        {context(this.props).dateRange && (
          <ConfiguredGrid
            config={pageConfig}
            context={context(this.props)}
            header={WIDGET_HEADER}
          />
        )}
        <div className={styles.metricsContainer}>
          {fleetTableIsLoading ? (
            <div className={styles.loadingContainer}>
              <Loader />
            </div>
          ) : (
            <MetricTable
              vessels={vessels}
              data={table}
              vesselClick={this.vesselClick}
              addCompare={this.toggleCompare}
              checkedVessels={compareVessels}
            />
          )}
        </div>
        <Pagination
          postsPerPage={PaginationSettings.postsPerPage}
          totalPosts={numberOfVesselsInGroup}
          currentPageNumber={
            queryContext.pageNum ? Number(queryContext.pageNum) : 1
          }
          paginate={paginate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const groupFilter = getGroupFilter(state, props);
  const dateFilter = getDateRangeFilter(state, props);
  const vessels = getVesselsInGroup(groupFilter.value, state.pageContext);

  const pageNum = props.queryContext.pageNum ? props.queryContext.pageNum : 1;
  const indexOfLastPost = pageNum * PaginationSettings.postsPerPage;
  const indexOfFirstPost = indexOfLastPost - PaginationSettings.postsPerPage;
  const vesselSlice = vessels.slice(indexOfFirstPost, indexOfLastPost);

  const vesselFilter = vesselSlice.map((vessel) => vessel.id);

  const filters = {
    isValid: groupFilter.isValid && dateFilter.isValid,
    groupFilter,
    dateFilter,
    vesselFilter,
  };

  const vesselGroupId = filters.groupFilter.value;

  return {
    filters,
    groups: getNonEmptyVesselGroups(state.pageContext) || [],
    vessels: vesselSlice,
    numberOfVesselsInGroup: vessels.length,
    pageContext: state.pageContext,
    pageConfig: selectPageConfig(
      state.user,
      state.pageConfig,
      state.pageContext.selectedVesselId,
      state.pageContext.vessels[state.pageContext.selectedVesselId],
      state.pageContext.groups[state.pageContext.selectedGroupId],
      "fleet",
      {
        vesselGroupId,
      }
    ),
    table: state.fetchFleetTable.data,
    fleetTableIsLoading: state.fetchFleetTable.isLoading,
    limits: getLimitsForVesselInQuery(state, props),
    datePickerVisible: state.datePicker.visible,
  };
};

const mapDispatchToProps = {
  fetchFleetPageData,
  removeAllCompareVessel,
  fetchCompare,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fleet);
